<template>
  <div class="sign-pop-ups" :class="[this.visible ? 'block' : 'hidden']">
    <div class="fixed inset-0 flex items-center justify-center bg-opacity-500">
      <div class="w-full bg-white divide-y divide-gray-500">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 cloase-btn" fill="#000" viewBox="0 0 24 24" stroke="#fff" @click.prevent="$emit('close')">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <div class="grid grid-cols-2 gap-4 h-full mt-12 mb-12">
          <div class="flex flex-col justify-center items-center ml-16 mr-16">
            <h2 class="text-2xl text-center mt-4">Complete your free account setup</h2>
            <div class="email-avatar mt-6 mb-6">
              <div class="m-1 mr-2 w-10 h-10 relative flex justify-center items-center rounded-full bg-gray-300 text-xl text-white uppercase"></div>
              {{ email }}
            </div>
            <InputText type="text" v-model="name" class="w-full" placeholder="Your name" />
            <span class="block text-red-500 italic text-left">{{ errors.first('name') }}</span>
            <AutoComplete class="p-fluid mt-3 w-full" v-model="country" field="name" :suggestions="filtered" placeholder="Country" @complete="searchCountry($event)" />
            <span class="block text-red-500 italic text-left">{{ errors.first('country') }}</span>
            <p class="mt-6">I want to</p>
            <SelectButton v-model="consult" :options="options" optionLabel="label" class="mt-3" />
            <div class="flex items-startS mt-12">
              <div class="flex items-center h-5">
                <input
                  v-model="consent"
                  aria-describedby="terms"
                  type="checkbox"
                  class="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800" />
              </div>
              <div class="ml-3 text-sm">
                <label for="terms" class="text-gray-900 dark:text-gray-300">
                  I agree to SmeSpot's <span class="text-blue-500 cursor-pointer" @click="$emit('tos')">terms of service</span> and
                  <span @click="$emit('privacy')" class="text-blue-500 cursor-pointer">privacy policy</span>
                </label>
              </div>
            </div>
            <button
              :disabled="errors.items.length > 0 && consent"
              :class="{ 'opacity-50 cursor-not-allowed': errors.items.length > 0 && consent }"
              class="sign-up bg-blue-500 hover:bg-blue-700 text-white p-3 rounded-full font-normal mt-14  "
              @click.prevent="proceed">
              Create my account
            </button>
          </div> 
          <div class="col image-container text-center bg-center-100" :style="{ 'background-image': `url(${require('@/assets/images/sign-up-all-users.jpg')})` }">
            <p class="welcome-text italic">We believe that the forces that drive business get stronger when combined</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countries from '@/plugins/country-regions';
import AutoComplete from 'primevue/autocomplete';
import InputText from 'primevue/inputtext';
import SelectButton from 'primevue/selectbutton';

export default {
  name: 'signup_common_modal',
  components: {
    AutoComplete,
    InputText,
    SelectButton,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    email: String,
  },
  data() {
    return {
      name: '',
      country: null,
      consent: false,
      consult: true,
      countries: countries,
      filtered: [],
      options: [
        { label: 'Find a Resource', value: false },
        { label: 'Provide a Service', value: true },
      ],
    };
  },
  methods: {
    proceed() {
      this.$emit('proceed', {
        name: this.name,
        country: this.country.name,
        consent: this.consent,
        consult: this.consult.value,
      });
    },
    searchCountry(event) {
      if (!event.query.trim().length) {
        this.filtered = [...this.countries];
      } else {
        this.filtered = this.countries.filter((c) => {
          return c.name.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }
    },
  },
};
</script>
