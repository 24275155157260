import Message from 'vue-m-message';
import 'vue-m-message/dist/index.css';

export default {
  install(Vue, options) {
    if (options.duration !== undefined && !isNaN(options.duration)) {
      Message.globals.options.duration = options.duration;
    }
    if (options.zIndex !== undefined && !isNaN(options.zIndex)) {
      Message.globals.options.zIndex = options.zIndex;
    }
    if (options.position !== undefined && options.position.length > 0) {
      Message.globals.options.position = options.position;
    }
    Message.globals.options.showClose = true;
    Message.globals.options.collapsable = false;
    if (options.name !== undefined && options.name.length > 0) {
      Vue.use(Message, { name: 'msg' });
    } else {
      Vue.use(Message);
    }
  },
};
