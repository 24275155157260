import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
// import { fas } from '@fortawesome/free-solid-svg-icons';
import {
  faHouseFlag,
  faIdCard,
  faCalendarDays,
  faMessage,
  faInbox,
  faEnvelopeCircleCheck,
  faPenRuler,
  faBarsProgress,
  faLocationCrosshairs,
  faPencil,
} from '@fortawesome/free-solid-svg-icons';
// import { far } from '@fortawesome/free-regular-svg-icons';
// import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// library.add(fas, far, fab);
library.add(faHouseFlag, faIdCard, faCalendarDays, faMessage, faInbox, faEnvelopeCircleCheck, faPenRuler, faBarsProgress, faLocationCrosshairs, faPencil);
Vue.component('font-awesome-icon', FontAwesomeIcon);
