import { Validator } from 'vee-validate';
import VeeValidate from 'vee-validate';
import { isStrongPassword, isMobilePhone } from 'validator';
import _axios from 'axios';
const axios = _axios.create({ baseURL: process.env.VUE_APP_BASE_URL });

export default {
  install(Vue) {
    Validator.extend('strong_password', {
      getMessage: (field) => `The ${field} value is not a strong password.`,
      validate: (value) => isStrongPassword(value, { minSymbols: 0 }),
    });

    Validator.extend('mobile_phone', {
      getMessage: (field) => `The ${field} value is not a strong password.`,
      validate: (value) => isMobilePhone(value),
    });

    Validator.extend('is_registered', {
      getMessage: (field) => `The ${field} value is registered before.`,
      validate: (value) => {
        return new Promise((resolve) => {
          axios.post('/auth/signup-eligibility', { email: value }).then(({ data }) => resolve(data.status && data.result));
        });
      },
    });

    Vue.use(VeeValidate);
  },
};
