<template>
  <footer class="flex">
    <div class="container mx-auto">
      <div class="grid grid-cols-2 my-10">
        <div class="left">
          <div class="grid grid-cols-3">
            <div class="logo font-bold">TRADE</div>
            <div class="col-span-2 latest-blog mr-10">
              <p class="small-heading font-bold">Latest Blog Post</p>
              <h3 class="py-2">Ready to get started?</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="grid grid-cols-3">
            <div class="ml-10">
              <p class="small-heading font-bold pb-2">Product</p>
              <ul>
                <li><a href=""></a>Product</li>
                <li><a href=""></a>Product</li>
                <li><a href=""></a>Product</li>
                <li><a href=""></a>Product</li>
              </ul>
            </div>
            <div>
              <p class="small-heading font-bold pb-2">Company</p>
              <ul>
                <li><a href=""></a>Company</li>
                <li><a href=""></a>Company</li>
                <li><a href=""></a>Company</li>
                <li><a href=""></a>Company</li>
              </ul>
            </div>
          </div>
          <a class="ml-10 copyright mt-20 block" href="">© 2010 — 2020 Privacy — Terms</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>
