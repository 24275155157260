import Vue from 'vue';
import App from './App.vue';
import router from '@/router/router';
import '@/plugins/font-awesome.js';
import './main.css';
import store from '@/store/store';
import '@/plugins/leaflet-maps.js';
import s3 from '@/plugins/s3';
import notificationService from '@/plugins/notification.js';
import '@/plugins/translator.js';
import VeeValidator from '@/plugins/validator.js';
import PrimeVue from 'primevue/config';
import axios from '@/plugins/axios';

import { Calendar, DatePicker } from 'v-calendar';
import VueSession from 'vue-session';

// Import PrimeVue styles
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

// Register PrimeVue as a plugin
Vue.use(PrimeVue);

Vue.config.productionTip = false;

//setup s3
Vue.use(s3, { axios });
Vue.use(VeeValidator);

// setup notification service
Vue.use(notificationService, {
  duration: 5000,
  zIndex: 10000,
  position: 'top-right',
  name: 'msg',
});
Vue.use(VueSession, { persist: true });

Vue.component('calendar', Calendar);
Vue.component('date-picker', DatePicker);

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount('#app');
