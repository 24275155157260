import axios from '@/plugins/axios';

function signIn(credentials) {
  return axios.post('auth/signin', credentials);
}

function signOut() {
  return axios.delete('auth/signOut');
}

function getUserInfo() {
  return axios.get('auth/self');
}

function requestAccountRecovery(email) {
  return axios.post('auth/recover-account', { email });
}

function requestAccountDeletion(password) {
  return axios.post('/auth/request-account-deletion', { password });
}

function deleteAccount(requestId, otpCode) {
  return axios.post('/auth/delete-account', { requestId, otpCode });
}

function verifyEmail(payload) {
  return axios.patch('auth/verify-email', payload);
}

function resetPassword(passwordResetRequestId, password) {
  return axios.post('auth/reset-password', { passwordResetRequestId, password });
}

export default {
  signIn,
  signOut,
  getUserInfo,
  requestAccountRecovery,
  requestAccountDeletion,
  deleteAccount,
  verifyEmail,
  resetPassword,
};
