<template>
  <div class="sign-pop-ups" :class="[this.visible ? 'block' : 'hidden']">
    <div class="fixed inset-0 flex items-center justify-center bg-opacity-500">
      <div class="w-full h-full bg-white divide-y divide-gray-500">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 cloase-btn" fill="#000" viewBox="0 0 24 24" stroke="#fff" @click.prevent="$emit('close')">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <div class="grid grid-cols-2 gap-4 h-full">
          <div class="flex flex-col justify-center items-center">
            <h3 class="text-2xl text-center">Log in to SmeSpot</h3>
            <input
              class="email appearance-none border border-black w-full p-3 px-3 mt-12 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              name="email"
              v-model="email"
              v-validate="'required|email'"
              placeholder="Email address" />
            <span class="block text-red-500 italic text-left">{{ errors.first('email') }}</span>
            <input
              class="password appearance-none border border-black w-full p-3 px-3 mt-6 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="password"
              name="password"
              v-model="password"
              v-validate="'required'"
              placeholder="Password" />
            <span class="block text-red-500 italic text-left">{{ errors.first('password') }}</span>

            <button
              :disabled="errors.items.length > 0"
              :class="{ 'opacity-50 cursor-not-allowed': errors.items.length > 0 }"
              class="sign-in bg-blue-500 hover:bg-blue-700 text-white py-3 font-normal mt-5"
              type="submit"
              @click.prevent="signIn">
              Log in
            </button>
            <button class="text-red-500 mt-5 text-base" @click.prevent="$emit('forgot-password')">Forgot Password</button>
            <div class="hr mt-7 mb-7">
              <span>or</span>
            </div>
            <div class="social-m">
              <button class="border facebook text-white py-3 font-normal" @click.prevent="$emit('facebook-sign-in')">Continue with Facebook</button>
              <button class="border google text-white py-3 font-normal mt-5" @click.prevent="$emit('google-sign-in')">Continue with Google</button>
            </div>
            <div class="hr mt-7"></div>
            <div class="flex items-center space-x-2 pt-2">
              <p>Don't have an account?</p>
              <button class="text-blue-500 text-base" @click="$emit('sign-up')">Sign Up</button>
            </div>
          </div>
          <div class="col image-container" :style="{ 'background-image': `url(${require('@/assets/images/sign-in.jpeg')})` }"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'sign_in_modal',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: '',
      password: '',
    };
  },
  methods: {
    signIn() {
      this.$emit('sign-in', { email: this.email, password: this.password });
    },
  },
};
</script>

<style lang="css" scoped>
.facebook {
  background-color: #3b5998;
}

.facebook:hover {
  background-color: #294175;
}

.google {
  background-color: #dd4b39;
}

.google:hover {
  background-color: #c23321;
}
</style>
