<template>
  <div class="sign-pop-ups cant-sign-in" :class="[this.visible ? 'block' : 'hidden']">
    <div class="fixed inset-0 flex items-center justify-center bg-opacity-500">
      <div class="w-full h-full bg-white divide-y divide-gray-500">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 cloase-btn" fill="#000" viewBox="0 0 24 24" stroke="#fff" @click.prevent="$emit('close')">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <div class="grid grid-cols-2 gap-4 h-full">
          <div class="flex flex-col justify-center items-center">
            <h3 class="text-2xl">Verifying email</h3>
            <p class="text-base mt-7 text-left">Email Verified</p>
            <button class="py-3 text-base text-blue-500 mt-5" type="submit" @click.prevent="$emit('login')">Return to log in</button>
          </div>
          <div class="col image-container" :style="{ 'background-image': `url(${require('@/assets/images/forgot-password.jpg')})` }"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth_service from '@/services/auth_service';

export default {
  name: 'verify_email',
  data() {
    return {
      accountRecoveryEmail: this.email,
      visible: false,
    };
  },
  computed: {
    dynamicData() {
      let query = this.$route.query;
      if (query.data) {
        return query.data;
      } else {
        return '';
      }
    },
  },
  watch: {
    dynamicData(encodedData) {
      if (encodedData.length > 0) {
        let data = JSON.parse(atob(encodedData));
        if (data.modal === 'verify-email') {
          this.visible = true;
          auth_service
            .verifyEmail(data.payload)
            .then(() => auth_service.getUserInfo())
            .then(({ data }) => this.$store.dispatch('setUser', data.user))
            .then(() => {
              this.$msg.success({ title: 'Email Verification', message: 'Thanks for the verification' });
              this.visible = false;
              this.$router.push({ name: 'consultant_update' });
            })
            .catch(({ response }) => this.$msg.error({ title: 'Email Verification', message: response.data.error }));
        }
        this.active_modal = data.modal;
      }
    },
  },
};
</script>
