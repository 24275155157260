import Vue from 'vue';
import VueTranslate from 'vue-translate-plugin';

Vue.use(VueTranslate);

Vue.locales({
  english: {
    'hello world': 'hola mundo',
  },
});
