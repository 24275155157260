import axios from '@/plugins/axios';

function signupConsultant(consultant) {
  return axios.post('consultant/signup', consultant);
}

function update(consultant) {
  return axios.post('/consultant/update', consultant);
}

function top12() {
  return axios.get('consultant/top/12');
}

function getConsultant(consultantId) {
  return axios.get(`/consultant/${consultantId}`);
}

function bookConsultant(clientID, consultantID, scheduledAt) {
  return axios.post('/consultant/book', { clientID, consultantID, scheduledAt });
}

function getBookings(consultantId) {
  return axios.get(`/consultant/bookings/${consultantId}`);
}

function search(terms) {
  return axios.get('consultant/search', {
    params: {
      q: terms.join(' '),
      page: 0,
      hitsPerPage: 12,
    },
  });
}

export default {
  signupConsultant,
  update,
  top12,
  getConsultant,
  bookConsultant,
  search,
  getBookings,
};
