import allCountries from 'country-region-data/data.json';

let all = allCountries.map((country) => {
  return {
    name: country.countryName,
    regions: country.regions.map((region) => region.name),
  };
});

export default all;
