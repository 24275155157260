import axios from 'axios';
export default {
  install(Vue, options) {
    Vue.prototype.$s3 = {
      getDpUrl: (key) => {
        return new Promise((success, failure) => {
          return options.axios
            .get(`/consultant/get-dp-download-url/${key}`)
            .then(({ data }) => success(data.url))
            .catch((err) => failure(err));
        });
      },
      uploadDp: (key, body) => {
        return new Promise((success, failure) => {
          return options.axios
            .get(`/consultant/get-dp-upload-url/${key}`)
            .then(({ data }) => axios.put(data.url, body).then(() => success(key)))
            .catch((err) => failure(err));
        });
      },
    };
  },
};
