<template>
  <div class="sign-pop-ups" :class="[this.visible ? 'block' : 'hidden']">
    <div class="fixed inset-0 flex items-center justify-center bg-opacity-500">
      <div class="w-full h-full bg-white divide-y divide-gray-500">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 cloase-btn" fill="#000" viewBox="0 0 24 24" stroke="#fff" @click.prevent="$emit('close')">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <div class="grid grid-cols-2 gap-4 h-full">
          <div class="flex flex-col justify-center items-center">
            <h3 class="text-2xl text-center">Hi {{ name }}!, <br />Please let us know your concent</h3>
            <div class="email-avatar mt-8">
              <div class="m-1 mr-2 w-10 h-10 relative flex justify-center items-center rounded-full bg-blue-500 text-xl text-white uppercase"></div>
              {{ email }}
            </div>
            <div class="privacy-policy mt-6 mx-20 pl-5">
              <p>
                Ut volutpat, mi eget tincidunt feugiat, eros lorem viverra nibh, vel mollis mi urna non tortor. Vivamus vehicula turpis a tellus sodales, eget luctus enim
                hendrerit. Fusce et enim quam. Aenean dapibus vehicula maximus. Quisque lobortis erat sit amet maximus elementum. Praesent quis facilisis sapien, vitae commodo leo.
                Vivamus nec dolor nunc. Nam malesuada id sapien at faucibus. Etiam vel venenatis tortor. Sed hendrerit pharetra purus ac interdum. Etiam imperdiet feugiat mi. Ut
                elit ex, luctus nec laoreet quis, ultricies et enim. Ut dignissim orci non enim facilisis, id commodo tortor feugiat. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Suspendisse id dolor nulla. Aliquam magna felis, iaculis id mauris eget, elementum rutrum orci. Vivamus suscipit sagittis aliquam. Sed viverra
                pellentesque neque ac vehicula. Morbi porta lectus sed pulvinar vestibulum. Nullam et risus feugiat, elementum lorem at, posuere felis. Donec et aliquet purus.
              </p>
            </div>
            <div class="social-m">
              <div class="grid grid-cols-2 gap-4 h-full">
                <button class="text-black py-2 rounded-full font-normal mt-5" @click.prevent="$emit('skip-for-now')">skip for now</button>
                <button class="text-white py-2 rounded-full font-normal mt-5 bg-blue-500 hover:bg-blue-700" @click.prevent="$emit('proceed')">Let’s get started</button>
              </div>
            </div>
          </div>
          <div class="col image-container" :style="{ 'background-image': `url(${require('@/assets/images/sign-up-all-users.jpg')})` }"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'privacy_policy_modal',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    email: String,
    name: String,
  },
  data() {
    return {
      agree: false,
    };
  },
};
</script>
